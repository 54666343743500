import React, { useState, useEffect } from "react";
import GseCustomer from "./gse-customer-request";

const GseCustomerPortal = () => {
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  const client = new faunadb.Client({ secret: process.env.GATSBY_FAUNA_DB });

  const COLLECTION_NAME = process.env.GATSBY_FAUNA_COLLECTION;
  //
  ///
  //Pull all used GSE-IDs
  //
  //
  const [existingGseCustomers, setexistingGseCustomers] = useState("");
  const [foundGseCustomer, setfoundGseCustomer] = useState("");

  useEffect(() => {
    getGseCustomers();
  }, []);

  const getGseCustomers = async () => {
    {
      const existingCustomers = await client.query(
        q.Paginate(q.Match(q.Index("find-gse-customer")), { size: 6000 })
      );

      setexistingGseCustomers(existingCustomers.data);
    }
  };
  //
  //
  // Set new Gse Number after finding the last used Gse ID from fauna
  //
  //
  //

  const GsePrefix = "GSE-";
  //
  //
  //Main form states
  //
  //
  //
  const [CustomerPortalFormData, setCustomerPortalFormData] = useState({
    homePage: "",
    aboutYourCompany: "",
    publicEmail: "",
    publicPhone: "",
    publicFax: "",
    publicAddress: "",
    publicCity: "",
    publicState: "AL",
    publicZip: "",
    serviceA: "",
    serviceB: "",
    serviceC: "",
    serviceD: "",
    additionalInfo: "",
    designerNotes: "",
    templateSelected: "Alpha",
    firstPage: "",
    secondPage: "",
    thirdPage: "",
    fourthPage: "",
    fifthPage: "",
  });

  //
  //
  //Login Stuff
  //
  //
  const [IsGseLoginAttemptCorrect, setIsGseLoginAttemptCorrect] = useState();

  const [GseCustomerLogin, setGseCustomerLogin] = useState({
    customerPin: "",
    customerGse: "",
  });

  switch (existingGseCustomers) {
    case "":
      var gseCustomerSearch = "";
      break;
    default:
      var gseCustomerSearch = existingGseCustomers.find(
        (o) =>
          o[10] === Number(GseCustomerLogin.customerPin) &&
          o[2] === "GSE-" + GseCustomerLogin.customerGse
      );
  }

  const GseTest = () => {
    if (
      typeof gseCustomerSearch !== "undefined" &&
      gseCustomerSearch.length > 0
    ) {
      setIsGseLoginAttemptCorrect(true);
      setfoundGseCustomer(gseCustomerSearch);
    } else {
      setIsGseLoginAttemptCorrect(false);
    }
  };
  //
  //
  //Form Functionality
  //
  //
  const [SubmitDisabled, setSubmitDisabled] = useState(false);

  const handleChange = (e) => {
    setCustomerPortalFormData({
      ...CustomerPortalFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeLogin = (e) => {
    setGseCustomerLogin({
      ...GseCustomerLogin,
      [e.target.name]: e.target.value,
    });
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  //
  //
  //
  //Create Database Entry//
  //
  //
  //
  //
  //
  //
  // Update Entry
  const updateCustomerEntry = async (_callback) => {
    await client.query(
      q.Update(q.Ref(q.Collection(COLLECTION_NAME), foundGseCustomer[1]), {
        data: {
          homePage: CustomerPortalFormData.homePage,
          aboutYourCompany: CustomerPortalFormData.aboutYourCompany,
          publicEmail: CustomerPortalFormData.publicEmail,
          publicPhone: CustomerPortalFormData.publicPhone,
          publicFax: CustomerPortalFormData.publicFax,
          publicAddress: CustomerPortalFormData.publicAddress,
          publicCity: CustomerPortalFormData.publicCity,
          publicState: CustomerPortalFormData.publicState,
          publicZip: CustomerPortalFormData.publicZip,
          serviceA: CustomerPortalFormData.serviceA,
          serviceB: CustomerPortalFormData.serviceB,
          serviceC: CustomerPortalFormData.serviceC,
          serviceD: CustomerPortalFormData.serviceD,
          additionalInfo: CustomerPortalFormData.additionalInfo,
          designerNotes: CustomerPortalFormData.designerNotes,
          templateSelected: CustomerPortalFormData.templateSelected,
          firstPage: CustomerPortalFormData.firstPage,
          secondPage: CustomerPortalFormData.secondPage,
          thirdPage: CustomerPortalFormData.thirdPage,
          fourthPage: CustomerPortalFormData.fourthPage,
          fifthPage: CustomerPortalFormData.fifthPage,
          gseStatus: "In Design",
        },
      })
    );

    _callback();
  };
  //
  //
  // End Fauna DB Shenanigans //
  //
  //
  //
  //
  // Regular Form Stuff //
  //

  const handleSubmit = (e) => {
    updateCustomerEntry(function () {});
    setSubmitDisabled(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "GSE Customer Submission",
        ...CustomerPortalFormData,
      }),
    })
      .then(() => window.open("./thank-you-customer", "_self"))
      .catch(() => window.open("../Error", "_self"));

    e.preventDefault();
  };

  let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  };

  switch (foundGseCustomer[0]) {
    case "In Progress":
      var hasCustomerInputDataBefore = false;
      break;
    case "In Design":
      var hasCustomerInputDataBefore = true;
      break;
    default:
      var hasCustomerInputDataBefore = false;
  }

  console.log(existingGseCustomers);
  console.log(foundGseCustomer);

  return (
    <>
      <div class="bg-gradient-to-l from-cyan-50 to-blue-50">
        <div class="pb-10 ">
          <div class="text-center relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
            <img
              class="mb-2 py-4 w-1/2 mx-auto"
              src="https://imgix.cosmicjs.com/1fac8f20-f7d5-11ec-8f84-3f01a4a22fe2-GSE-partnership-lockup-long.svg"
            />
            <div class="max-w-xl mx-auto border-b pb-3 flex justify-center">
              <div>
                {" "}
                <a
                  href="mailto:Gse@consultfgc.com"
                  class="pr-4 mx-auto mt-6 font-medium text-xl text-blue-500 hover:underline max-w-4xl"
                >
                  Gse@consultfgc.com
                </a>
              </div>
              <div>
                {" "}
                <a
                  href="tel:7274772023"
                  class="border-l pl-4 mx-auto mt-6 font-medium text-xl text-purple-700 hover:underline max-w-4xl"
                >
                  727-477-2023
                </a>
                <p class="inline ml-2 text-gray-500 ">Ext. 118</p>
              </div>
            </div>
            <h1 class="mt-8 text-4xl font-extrabold tracking-tight text-blue-800 sm:text-6xl">
              GSE Customers Only.
            </h1>
            <p class="mx-auto mt-6 text-xl text-gray-700 max-w-5xl">
              Once you receive your welcome packet from us, go ahead and gather
              all the content requested and when you're ready input your details
              here to officially get your project started.
            </p>
          </div>
        </div>
      </div>

      <section
        class="relative bg-gradient-to-l from-cyan-50 to-blue-50 pb-20"
        aria-labelledby="contact-heading"
      >
        <div class="" aria-hidden="true"></div>

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="relative">
            <div class="pss mb-10 bg-gradient-to-r from-blue-900 to-teal-700 shadow-md px-4 py-5 sm:rounded-lg sm:p-10">
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <h3 class="text-lg font-medium leading-6 text-white">
                    Customer Sign In
                  </h3>
                  <p class="mt-1 text-sm text-gray-50">
                    Enter GSE Credentials Here
                  </p>
                </div>{" "}
                {IsGseLoginAttemptCorrect === true ? (
                  <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="grid grid-cols-8 gap-6">
                      <div class="col-span-6 sm:col-span-3">
                        <label
                          for="customerPin"
                          class="block text-sm font-medium text-yellow-100"
                        >
                          Pin # (e.g. 12345)
                        </label>
                        <input
                          type="number"
                          name="customerPin"
                          id="customerPin"
                          onChange={handleChangeLogin}
                          disabled
                          class="mt-1 py-3 px-4 block w-full text-gray-900 rounded-md "
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                        <label
                          for="customerGse"
                          class="block text-sm font-medium text-yellow-100"
                        >
                          GSE ID (Enter # after "GSE-")
                        </label>
                        <input
                          type="number"
                          name="customerGse"
                          id="customerGse"
                          onChange={handleChangeLogin}
                          disabled
                          class="mt-1 py-3 px-4 block w-full text-gray-900 rounded-md"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-2">
                        <button
                          type="button"
                          onClick={GseTest}
                          className="pointer-events-none mt-6 border-yellow-300 py-3 px-4 block w-full text-white bg-yellow-300 font-medium text-purple-900 rounded-md opacity-80"
                        >
                          Enter
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="grid grid-cols-8 gap-6">
                      <div class="col-span-6 sm:col-span-3">
                        <label
                          for="customerPin"
                          class="block text-sm font-medium text-yellow-100"
                        >
                          Pin # (e.g. 12345)
                        </label>
                        <input
                          type="number"
                          name="customerPin"
                          id="customerPin"
                          onChange={handleChangeLogin}
                          required
                          class="mt-1 py-3 px-4 block w-full text-gray-900 bg-purple-50 focus:outline-purple-600 rounded-md"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                        <label
                          for="customerGse"
                          class="block text-sm font-medium text-yellow-100"
                        >
                          GSE ID (Enter # after "GSE-")
                        </label>
                        <input
                          type="number"
                          name="customerGse"
                          id="customerGse"
                          onChange={handleChangeLogin}
                          required
                          class="mt-1 py-3 px-4 block w-full text-gray-900 rounded-md"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-2">
                        <button
                          type="button"
                          onClick={GseTest}
                          className="mt-6 border-yellow-300 py-3 px-4 block w-full text-white bg-yellow-300 font-medium text-purple-900 rounded-md hover:opacity-80"
                        >
                          Enter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {IsGseLoginAttemptCorrect === true &&
            hasCustomerInputDataBefore === false ? (
              <div class="space-y-6">
                <form
                  method="POST"
                  target="_self"
                  id="GSE Customer Submission"
                  name="GSE Customer Submission"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                >
                  <div class="bg-white border shadow-md px-4 py-5 sm:rounded-lg sm:p-10">
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                      <div class="md:col-span-1">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                          Customer Information
                        </h3>
                        <p class="mt-1 text-sm text-gray-500">
                          Please review the information provided to us by GSE
                          and contact us if anything needs to be corrected.
                        </p>
                      </div>
                      <div class="mt-5 md:mt-0 md:col-span-2">
                        <div class="grid grid-cols-6 gap-6">
                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="companyGseID"
                              class="block text-sm font-medium text-purple-700"
                            >
                              GSE Identifier{" "}
                              <span class="text-gray-400">(GSE ID)</span>
                            </label>
                            <input
                              type="text"
                              value={foundGseCustomer[2]}
                              disabled
                              class="mt-1 py-3 px-4 block w-full font-medium text-purple-900 bg-purple-100 focus:outline-purple-600 border border-purple-800 rounded-md"
                            />
                          </div>
                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="companyName"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Company Name
                            </label>
                            <input
                              type="text"
                              value={foundGseCustomer[3]}
                              disabled
                              class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="firstName"
                              class="block text-sm font-medium text-gray-700"
                            >
                              First name
                            </label>
                            <input
                              type="text"
                              value={foundGseCustomer[4]}
                              disabled
                              autocomplete="given-name"
                              class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="lastName"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Last name
                            </label>
                            <input
                              type="text"
                              value={foundGseCustomer[5]}
                              disabled
                              class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="companyEmail"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Email address{" "}
                            </label>
                            <input
                              type="email"
                              name="correctedEmail"
                              id="correctedEmail"
                              value={foundGseCustomer[6]}
                              disabled
                              class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="companyPhone"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Contact Phone{" "}
                            </label>
                            <input
                              type="text"
                              value={formatPhoneNumber(foundGseCustomer[7])}
                              disabled
                              class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>

                          <div class="col-span-6 sm:col-span-6">
                            <label
                              for="webPackage"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Website Package
                            </label>
                            <input
                              value={foundGseCustomer[8]}
                              disabled
                              class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center py-14">
                    {" "}
                    <h1 class="mt-16 text-4xl font-extrabold tracking-tight text-teal-800 sm:text-6xl">
                      Website Data
                    </h1>
                    <p class="mx-auto mt-6 text-xl text-gray-700 max-w-6xl">
                      The following text entry fields are for you to enter in
                      company, service, and/or product information, etc. Don’t
                      worry you’ll be able to edit this information at any time.
                      Enter in as much or as little information as you want to
                      and remember for Consult FGC designers to begin your
                      website design this entire form MUST be completed. If you
                      have questions or need help with this at anytime please
                      call or email us directly.
                    </p>
                    <div class="mt-4 max-w-xl mx-auto border-b pb-3 flex justify-center">
                      <div>
                        {" "}
                        <a
                          href="mailto:Gse@consultfgc.com"
                          class="pr-4 mx-auto mt-6 font-medium text-xl text-blue-500 hover:underline max-w-4xl"
                        >
                          Gse@consultfgc.com
                        </a>
                      </div>
                      <div>
                        {" "}
                        <a
                          href="tel:7274772023"
                          class="border-l pl-4 mx-auto mt-6 font-medium text-xl text-purple-700 hover:underline max-w-4xl"
                        >
                          727-477-2023
                        </a>
                        <p class="inline ml-2 text-gray-500 ">Ext. 118</p>
                      </div>
                    </div>
                  </div>
                  <div class="general info mt-10 bg-white border shadow-md px-4 py-5 sm:rounded-lg sm:p-10">
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                      <div class="md:col-span-1">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                          Company Information
                        </h3>
                        <p class="mt-1 text-sm text-gray-500">
                          General company info
                        </p>
                      </div>
                      <div class="mt-5 md:mt-0 md:col-span-2">
                        <div class="grid grid-cols-6">
                          <div class=" mt-5 mb-3 md:mt-0 md:col-span-6">
                            <label
                              for="mission"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Home Page
                            </label>
                            <div class="col-span-6 sm:col-span-3">
                              <textarea
                                name="homePage"
                                onChange={handleChange}
                                cols="40"
                                rows="5"
                                required
                                class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                              ></textarea>
                            </div>
                          </div>
                          <div class=" mt-5 mb-3 md:mt-4 md:col-span-6">
                            <label
                              for="about your company"
                              class="block text-sm font-medium text-gray-700"
                            >
                              About your Company
                            </label>
                            <div class="col-span-6 sm:col-span-3">
                              <textarea
                                name="aboutYourCompany"
                                onChange={handleChange}
                                cols="40"
                                rows="5"
                                required
                                class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                              ></textarea>
                            </div>
                          </div>{" "}
                        </div>

                        <div class="mt-4 grid grid-cols-6 gap-6">
                          <div class="col-span-6 sm:col-span-2">
                            <label
                              for="public email"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Public Contact Email
                            </label>
                            <input
                              type="email"
                              name="publicEmail"
                              onChange={handleChange}
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>

                          <div class="col-span-6 sm:col-span-2">
                            <label
                              for="public phone"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Public Contact Phone
                            </label>
                            <input
                              type="number"
                              name="publicPhone"
                              onChange={handleChange}
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div class="col-span-6 sm:col-span-2">
                            <label
                              for="public fax"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Public Fax Number
                            </label>
                            <input
                              type="number"
                              name="publicFax"
                              onChange={handleChange}
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>

                          <div class="col-span-6 sm:col-span-6">
                            <label
                              for="public address"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Street Address
                            </label>
                            <input
                              type="text"
                              name="publicAddress"
                              onChange={handleChange}
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>

                          <div class="col-span-6 sm:col-span-2">
                            <label
                              for="public city"
                              class="block text-sm font-medium text-gray-700"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              name="publicCity"
                              onChange={handleChange}
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div class="col-span-6 sm:col-span-2">
                            <label
                              for="public state"
                              class="block text-sm font-medium text-gray-700"
                            >
                              State
                            </label>
                            <select
                              name="publicState"
                              onChange={handleChange}
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            >
                              <option selected="selected" value="AL">
                                Alabama
                              </option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                          <div class="col-span-6 sm:col-span-2">
                            <label
                              for="public zip"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Zip Code
                            </label>
                            <input
                              type="number"
                              name="publicZip"
                              onChange={handleChange}
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div class="services mt-10 bg-white border shadow-md px-4 py-5 sm:rounded-lg sm:p-10">
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                      <div class="md:col-span-1">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                          Services/Features
                        </h3>
                        <p class="mt-1 text-sm text-gray-500">
                          Enter in your company services that you want
                          highlighted. If you offer more than 4 primary services
                          don’t worry you can enter this into the additional
                          information field below.
                        </p>
                      </div>
                      <div class="mt-5 md:mt-0 md:col-span-2">
                        <div class="grid grid-cols-6">
                          <div class=" mt-5 mb-3 md:mt-0 md:col-span-6">
                            <label
                              for="service 1"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Service/Feature 1
                            </label>
                            <div class="col-span-6 sm:col-span-3">
                              <textarea
                                name="serviceA"
                                onChange={handleChange}
                                cols="40"
                                rows="5"
                                required
                                class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                              ></textarea>
                            </div>
                          </div>

                          <div class=" mt-5 mb-3 md:mt-4 md:col-span-6">
                            <label
                              for="service 2"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Service/Feature 2
                            </label>
                            <div class="col-span-6 sm:col-span-3">
                              <textarea
                                name="serviceB"
                                onChange={handleChange}
                                cols="40"
                                rows="5"
                                class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                              ></textarea>
                            </div>
                          </div>
                          <div class=" mt-5 mb-3 md:mt-4 md:col-span-6">
                            <label
                              for="service 3"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Service/Feature 3
                            </label>
                            <div class="col-span-6 sm:col-span-3">
                              <textarea
                                name="serviceC"
                                onChange={handleChange}
                                cols="40"
                                rows="5"
                                class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                              ></textarea>
                            </div>
                          </div>
                          <div class=" mt-5 mb-3 md:mt-4 md:col-span-6">
                            <label
                              for="service 4"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Service/Feature 4
                            </label>
                            <div class="col-span-6 sm:col-span-3">
                              <textarea
                                name="serviceD"
                                onChange={handleChange}
                                cols="40"
                                rows="5"
                                class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                              ></textarea>
                            </div>
                          </div>
                          <div class=" mt-5 mb-3 md:mt-4 md:col-span-6">
                            <label
                              for="additional info"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Additional Information - If there is any
                              additional copy you want added to your new website
                              enter it here.
                            </label>
                            <div class="col-span-6 sm:col-span-3">
                              <textarea
                                name="additionalInfo"
                                onChange={handleChange}
                                cols="40"
                                rows="5"
                                class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                              ></textarea>
                            </div>
                          </div>
                          <div class="mt-5 mb-3 md:mt-4 md:col-span-6">
                            <label
                              for="designer notes"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Designer Notes - Is there anything you want to
                              communicate to your designer add it here (e.g. Our
                              company is the best please make us look good, etc.
                              or We love red, white, and blue color schemes
                              please make sure these are prominent).
                            </label>
                            <div class="col-span-6 sm:col-span-3">
                              <textarea
                                name="designerNotes"
                                onChange={handleChange}
                                cols="40"
                                rows="5"
                                class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div class="text-center py-14">
                    {" "}
                    <h1 class="mt-16 text-4xl font-extrabold tracking-tight text-orange-700 sm:text-6xl">
                      Website Templates
                    </h1>
                    <p class="mx-auto mt-6 text-xl text-gray-700 max-w-6xl">
                      The following area will reference the website templates
                      designed below, please click through them to get a better
                      idea on what you would like your new site to look like.
                    </p>
                    <ul
                      role="list"
                      class="mt-10 mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-6"
                    >
                      <li />
                      <li class="relative">
                        <a href="https://gse-temp-alpha.com/" target="blank">
                          {" "}
                          <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                            <img
                              src="https://imgix.cosmicjs.com/aaa831d0-0db3-11ed-b476-13ceb56f12f2-Web-Template-Avatar-Alpha.jpg"
                              alt=""
                              class="border-blue-400 border-2 rounded-lg object-cover pointer-events-none group-hover:opacity-75"
                            />
                            <button
                              type="button"
                              class="absolute inset-0 focus:outline-none"
                            ></button>
                          </div>
                        </a>

                        <p class="pt-2 block text-sm font-medium text-gray-500 pointer-events-none">
                          Alpha
                        </p>
                      </li>{" "}
                      <li class="relative">
                        <a href="https://gse-temp-bravo.com/" target="blank">
                          {" "}
                          <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                            <img
                              src="https://imgix.cosmicjs.com/aaa9b870-0db3-11ed-b476-13ceb56f12f2-Web-Template-Avatar-Bravo.jpg"
                              alt=""
                              class="border-blue-400 border-2 rounded-lg object-cover pointer-events-none group-hover:opacity-75"
                            />
                            <button
                              type="button"
                              class="absolute inset-0 focus:outline-none"
                            ></button>
                          </div>
                        </a>

                        <p class="pt-2 block text-sm font-medium text-gray-500 pointer-events-none">
                          Bravo
                        </p>
                      </li>{" "}
                      <li />
                    </ul>
                  </div>
                  <div class="templates mt-10 bg-white border shadow-md px-4 py-5 sm:rounded-lg sm:p-10">
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                      <div class="md:col-span-1">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                          Website Template/Design
                        </h3>
                        <p class="mt-1 text-sm text-gray-500">
                          Utlizing the references above, please select your
                          options in accordance with your preferences/needs.
                        </p>
                      </div>
                      <div class="mt-5 md:mt-0 md:col-span-2">
                        <div class="grid grid-cols-6">
                          <div class="col-span-6 sm:col-span-6">
                            <label
                              for="select template"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Select a Website Template
                            </label>
                            <select
                              name="templateSelected"
                              onChange={handleChange}
                              required
                              class="mt-1 mb-5 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            >
                              <option selected="selected" value="Alpha">
                                Alpha
                              </option>
                              <option value="Beta">Beta</option>
                            </select>
                          </div>
                          <div class="mt-5 col-span-6 sm:col-span-2">
                            <label
                              for="page 1"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Page 1 - (e.g. Services A)
                            </label>
                            <input
                              type="text"
                              name="firstPage"
                              onChange={handleChange}
                              required
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div class="ml-4 mt-5 col-span-6 sm:col-span-2">
                            <label
                              for="page 2"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Page 2
                            </label>
                            <input
                              type="text"
                              name="secondPage"
                              onChange={handleChange}
                              required
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div class="ml-4 mt-5 col-span-6 sm:col-span-2">
                            <label
                              for="page 3"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Page 3
                            </label>
                            <input
                              type="text"
                              name="thirdPage"
                              onChange={handleChange}
                              required
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>

                          <div class="mt-5 col-span-6 sm:col-span-3">
                            <label
                              for="page 4"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Page 4 - If Applicable
                            </label>
                            <input
                              type="text"
                              name="fourthPage"
                              onChange={handleChange}
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>
                          <div class="ml-4 mt-5 col-span-6 sm:col-span-3">
                            <label
                              for="page 5"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Page 5 - If Applicable
                            </label>
                            <input
                              type="text"
                              name="fifthPage"
                              onChange={handleChange}
                              class="mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div class="mb-20">
                    <button
                      type="submit"
                      class="w-full mt-8  inline-flex justify-center py-4 px-4 text-md font-medium rounded-md text-white bg-gradient-to-r from-purple-500 to-pink-800 hover:opacity-70 focus:outline-none"
                    >
                      Submit Company Content
                    </button>
                  </div>
                  <div class="border-blue-900 border mb-20 " />
                </form>
                <div class="relative upload mt-10 bg-blue-700 shadow-md px-4 py-5 sm:rounded-lg sm:p-10">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-white">
                        Upload Your Content
                      </h3>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6">
                        <div class=" mt-5 mb-3 md:mt-0 md:col-span-6">
                          <label
                            for="mission"
                            class="block text-sm font-medium text-white"
                          >
                            This is your secure company drive that you will use
                            to upload all images, logos and anything else you
                            would like to include on your site.
                          </label>
                          <label
                            for="mission"
                            class="mt-4 block text-sm font-medium text-white"
                          >
                            Make sure to use the same email as listed above, if
                            you would like to change this please reach out to
                            {""}
                            <a
                              href="mailto:Gse@consultfgc.com"
                              class="hover:underline"
                            >
                              {""} ConsultFGC.
                            </a>
                          </label>
                          <div class="">
                            <a
                              href={foundGseCustomer[9]}
                              target="blank"
                              class="w-full mt-8  inline-flex justify-center py-4 px-4 text-md font-medium rounded-md text-blue-800 bg-gradient-to-r from-yellow-200 via-yellow-300 to-yellow-400 hover:opacity-70 focus:outline-none"
                            >
                              Visit {foundGseCustomer[3]}'s Content Drive
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : (
              <div class="text-center font-bold text-gray-400 text-md"></div>
            )}

            {IsGseLoginAttemptCorrect === true &&
            hasCustomerInputDataBefore === true ? (
              <div class="">
                <div class="text-center mb-16">
                  {" "}
                  <h1 class="mt-16 text-4xl font-extrabold tracking-tight text-cyan-600 sm:text-6xl">
                    We Have Received Your Data
                  </h1>
                  <p class="mx-auto mt-6 text-xl text-gray-700 max-w-6xl">
                    You have successfully input the requested data for your new
                    Website and we are currently in the building stages. If you
                    feel you have made an error please contact us below and we
                    will get in touch with you on correcting whatever
                    information that needs to be changed!
                  </p>
                  <p class="mx-auto mt-6 text-xl text-gray-700 max-w-6xl">
                    Also feel free to keep uploading content to your drive
                    folder but just give us a heads up on the new content added.
                  </p>
                  <div class="mt-4 max-w-xl mx-auto border-b pb-3 flex justify-center">
                    <div>
                      {" "}
                      <a
                        href="mailto:Gse@consultfgc.com"
                        class="pr-4 mx-auto mt-6 font-medium text-xl text-blue-500 hover:underline max-w-4xl"
                      >
                        Gse@consultfgc.com
                      </a>
                    </div>
                    <div>
                      {" "}
                      <a
                        href="tel:7274772023"
                        class="border-l pl-4 mx-auto mt-6 font-medium text-xl text-purple-700 hover:underline max-w-4xl"
                      >
                        727-477-2023
                      </a>
                      <p class="inline ml-2 text-gray-500 ">Ext. 118</p>
                    </div>
                  </div>
                </div>
                <div class="bg-white border shadow-md px-4 py-5 sm:rounded-lg sm:p-10">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Customer Information
                      </h3>
                      <p class="mt-1 text-sm text-gray-500">
                        Store this information for future reference.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="companyGseID"
                            class="block text-sm font-medium text-purple-700"
                          >
                            GSE Identifier{" "}
                            <span class="text-gray-400">(GSE ID)</span>
                          </label>
                          <input
                            type="text"
                            value={foundGseCustomer[2]}
                            disabled
                            class="mt-1 py-3 px-4 block w-full font-medium text-purple-900 bg-purple-100 focus:outline-purple-600 border border-purple-800 rounded-md"
                          />
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="companyName"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Company Name
                          </label>
                          <input
                            type="text"
                            value={foundGseCustomer[3]}
                            disabled
                            class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                          />
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="firstName"
                            class="block text-sm font-medium text-gray-700"
                          >
                            First name
                          </label>
                          <input
                            type="text"
                            value={foundGseCustomer[4]}
                            disabled
                            autocomplete="given-name"
                            class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="lastName"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Last name
                          </label>
                          <input
                            type="text"
                            value={foundGseCustomer[5]}
                            disabled
                            class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="companyEmail"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Email address{" "}
                          </label>
                          <input
                            type="email"
                            name="correctedEmail"
                            id="correctedEmail"
                            value={foundGseCustomer[6]}
                            disabled
                            class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="companyPhone"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Contact Phone{" "}
                          </label>
                          <input
                            type="text"
                            value={formatPhoneNumber(foundGseCustomer[7])}
                            disabled
                            class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-6">
                          <label
                            for="webPackage"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Website Package
                          </label>
                          <input
                            value={foundGseCustomer[8]}
                            disabled
                            class="bg-gray-200 mt-1 py-3 px-4 block w-full text-gray-900  focus:outline-cyan-600 border border-gray-300 rounded-md"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div class="relative upload mt-20 bg-blue-700 shadow-md px-4 py-5 sm:rounded-lg sm:p-10">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-white">
                        Upload Your Content
                      </h3>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6">
                        <div class=" mt-5 mb-3 md:mt-0 md:col-span-6">
                          <label
                            for="mission"
                            class="block text-sm font-medium text-white"
                          >
                            This is your secure company drive that you will use
                            to upload all images, logos and anything else you
                            would like to include on your site.
                          </label>
                          <label
                            for="mission"
                            class="mt-4 block text-sm font-medium text-white"
                          >
                            Make sure to use the same email as listed above, if
                            you would like to change this please reach out to
                            {""}
                            <a
                              href="mailto:Gse@consultfgc.com"
                              class="hover:underline"
                            >
                              {""} ConsultFGC.
                            </a>
                          </label>
                          <div class="">
                            <a
                              href={foundGseCustomer[9]}
                              target="blank"
                              class="w-full mt-8  inline-flex justify-center py-4 px-4 text-md font-medium rounded-md text-blue-800 bg-gradient-to-r from-yellow-200 via-yellow-300 to-yellow-400 hover:opacity-70 focus:outline-none"
                            >
                              Visit {foundGseCustomer[3]}'s Content Drive
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : (
              <div class="text-center font-bold text-gray-400 text-md"></div>
            )}

            {IsGseLoginAttemptCorrect === false ? (
              <div class="max-w-4xl mx-auto rounded-md bg-red-50 pt-4 pb-4">
                <div class="flex justify-center items-center">
                  <div class="flex-shrink-0">
                    <svg
                      class="h-7 w-7 text-red-600"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18.919 10.431c.24.292.258 1.004-.027 1.284-.252.246-.52.205-1.051.205v-1.71c.503 0 .864-.039 1.078.221zm-5.438.149c-.19.581-.188 2.651 0 3.217.216.654 1.067.654 1.283 0 .19-.569.19-2.639 0-3.217-.214-.659-1.067-.662-1.283 0zm10.519-3.551v9.941l-7.029 7.03h-9.942l-7.029-7.029v-9.942l7.029-7.029h9.941l7.03 7.029zm-17.09 4.866c-1-.433-1.457-.478-1.457-1.142 0-.793.885-.792 1.119-.437.094.145.141.35.141.618h1.088v-.157c0-.268-.044-.498-.131-.689-.286-.633-.918-.823-1.61-.823-.541 0-.966.137-1.275.41-.661.583-.729 2.082.354 2.669.644.35 1.419.396 1.61.91.072.195.107.636-.158.851-.3.244-.837.227-1.066-.067-.162-.206-.149-.533-.149-.795h-1.136c0 .538.017 1.025.414 1.425.553.559 2.118.556 2.812.012.707-.557.861-2.163-.556-2.785zm4.93-2.52h-3.591v.929h1.229v4.693h1.134v-4.693h1.229v-.929zm3.879.709c-.135-.257-.327-.458-.58-.602-.508-.292-1.522-.292-2.031 0-.252.145-.444.345-.578.602-.435.835-.438 3.367 0 4.205.134.257.326.454.578.591.509.276 1.523.275 2.031 0 .253-.137.445-.333.58-.591.435-.838.434-3.37 0-4.205zm2.971-.709h-1.983v5.623h1.134v-2.244c.638 0 .939.021 1.338-.103 1.509-.466 1.509-3.276-.489-3.276z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-lg font-medium text-red-700">
                      <span class="font-bold">Customer Not Found</span> - Check
                      Your Login Credentials or contact{" "}
                      <a
                        href="mailto:Gse@consultfgc.com"
                        class="hover:underline"
                      >
                        Gse@consultfgc.com
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default GseCustomerPortal;
